import React, { PropsWithChildren, useState } from 'react';
import { IPrismicImage, IPrismicText, IPrismicUrl } from '@lib/prismic/types';
import { IMegaMenuColumn } from '@components/shared/Navigation/components/MainHeader/Desktop/MegaMenu/MegaMenu';
import { ILink } from '@components/shared/Navigation/components/MainHeader/components/NavLinks/links';
import parseUrl from '@lib/prismic/util/parseUrl';
import { useRouter } from 'next/router';
import ShopNavigationContext from './ShopNavigationContext';

/* eslint-disable camelcase */
interface IPrismicShopLink {
  link_title: IPrismicText[];
  link_url: IPrismicUrl;
}
export interface IPrismicShopNavData {
  megamenu_shop_image: IPrismicImage;
  megamenu_shop_title: IPrismicText[];
  megamenu_shop_description: IPrismicText[];
  megamenu_shop_url: IPrismicUrl;
  shop_links_1_section_title: IPrismicText[];
  shop_links_1_group: IPrismicShopLink[];
  shop_links_2_section_title: IPrismicText[];
  shop_links_2_group: IPrismicShopLink[];
  shop_links_3_section_title: IPrismicText[];
  shop_links_3_group: IPrismicShopLink[];
}

const ShopNavigationProvider = ({
  prismicNavData, children
}: PropsWithChildren<{ prismicNavData?: IPrismicShopNavData }>) => {
  const [isShopNavigationHovered, setShopNavigationHovered] = useState<boolean>(false);
  const router: NextRouter = useRouter();
  const hideShopSubMenu = router.pathname.startsWith('/shop')
  || router.pathname.startsWith('/products/details');

  let shopNavData: Array<IMegaMenuColumn> = [];
  if (prismicNavData) {
    shopNavData = [
      {
        title: prismicNavData.shop_links_1_section_title[0].text,
        links: getLinks(prismicNavData.shop_links_1_group)
      },
      {
        title: prismicNavData.shop_links_2_section_title[0].text,
        links: getLinks(prismicNavData.shop_links_2_group)
      },
      {
        title: prismicNavData.shop_links_3_section_title[0].text,
        links: getLinks(prismicNavData.shop_links_3_group)
      },
      {
        title: prismicNavData.megamenu_shop_title[0].text!,
        text: prismicNavData.megamenu_shop_description?.[0]?.text,
        image: {
          src: prismicNavData.megamenu_shop_image.url,
          link: parseUrl(prismicNavData?.megamenu_shop_url.url)
        }
      }
    ];
  }

  return (
    <ShopNavigationContext.Provider
      value={{
        shopNavigationData: shopNavData,
        isShopNavigationHovered,
        setShopNavigationHovered,
        hideShopSubMenu
      }}
    >
      {children}
    </ShopNavigationContext.Provider>
  );
};

function getLinks(prismicGroup: IPrismicShopLink[]) {
  return [
    ...prismicGroup
      .filter((link) => link)
      .map((link) => ({
        title: link.link_title[0]?.text,
        url: parseUrl(link.link_url.url),
      }) as ILink)
  ];
}

export default ShopNavigationProvider;
